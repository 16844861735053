<!-- eslint-disable -->
<template>
  <div>
    <Register />
  </div>
</template>
<!-- eslint-disable -->

<script>
import Register from "../components/Register.vue";
export default {
  name: "register",
  components: {
    Register,
  },
};
</script>
<!-- eslint-disable -->

<style></style>
