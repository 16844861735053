<!-- eslint-disable -->
<template>
  <div>
    <Login />
  </div>
</template>
<!-- eslint-disable -->

<script>
import Login from "../components/Login.vue";
export default {
  name: "login",
  components: {
    Login,
  },
};
</script>
<!-- eslint-disable -->

<style></style>
